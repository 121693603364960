import React from 'react';
import '../styles/Home.scss';
import DataCard from './DataCard';
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa'
import { IoIosArrowDown } from 'react-icons/io';
import ima1 from '../images/img_1.jpeg';
import ima2 from '../images/img_2.jpeg';
import ima3 from '../images/img_3.jpeg';
import ima4 from '../images/img_4.jpeg';
import ima5 from '../images/img_5.jpeg';
import ima6 from '../images/img_6.jpeg';
import ima7 from '../images/img_7.jpg';
import ima8 from '../images/img_8.jpg';
import nase from '../images/nase.png'

const Home: React.FC = () => {
  return (
    <div className="home-container container-fluid d-flex flex-column align-items-center m-0 p-0">
      <div className="row mt-5">
        <div className="col-12 d-flex flex-column align-items-center justify-content-center" style={{ height: '70vh' }}>
          <img src={nase} alt="" className='logo mb-5' />
          <div>
              <h1 className='text-center'>NASE DECORANDO</h1>
              <h3 className='text-center'>Haz realidad tus ideas en decoración.</h3>
          </div>

        </div>

        <div className="col-12 mb-5">
          <div className='w-100 d-flex justify-content-center'>
            <a className="cta mx-3" href="https://wa.me/573183061943?text=Hola%20NASE,%20me%20gustaría%20recibir%20información" target="_blank">
              <FaWhatsapp size={30} color="#000000" />
            </a>
            <a className="cta mx-3" href="https://www.instagram.com/nase_decorando?igsh=anl5bDFwcjgzbjRx" target="_blank">
              <FaInstagram size={30} color="#000000" />
            </a>
            <a className="cta mx-3" href="https://www.facebook.com/share/6FaEaXDW4kPMsRAA/?mibextid=qi2Omg" target="_blank">
              <FaFacebook size={30} color="#000000" />
            </a>
          </div>
        </div>
        <div className="col-12 mt-4 mb-5" id="1_img">
          <div className='w-100 d-flex justify-content-center'>
            <a className="cta mx-3" href="#1_img">
              <IoIosArrowDown size={30} color="#000000" />
            </a>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={false}
            botonTxt=""
            titulo="Especialistas en madera"
            subTitulo='Trabajamos con la mejor madera, ofreciendo productos que combinan estilo y durabilidad'
            backgroundImage={ima1}></DataCard>
        </div>
        <div className="col-lg-6 col-sm-12 mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={false}
            botonTxt=""
            titulo="Variedad de diseños"
            subTitulo='Tenemos experiencia fabricando diversos articulos de decoración, desde mesas hasta closets.'
            backgroundImage={ima7}></DataCard>
        </div>
        <div className="col-12 mt-4 mb-4">
          <h1 className='text-center'>
            Nuestra oferta
          </h1>
          <h2 className='text-center'>
            Fabricamos productos para el hogar 100% personalizados
          </h2>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt=""
            titulo=""
            subTitulo=''
            backgroundImage={ima1}></DataCard>
        </div>
        <div className="col-lg-4 col-sm-6  mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt=""
            titulo=""
            subTitulo=''
            backgroundImage={ima2}></DataCard>
        </div>
        <div className="col-lg-4 col-sm-6  mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt=""
            titulo=""
            subTitulo=''
            backgroundImage={ima3}></DataCard>
        </div>
        <div className="col-lg-4 col-sm-6  mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt=""
            titulo=""
            subTitulo=''
            backgroundImage={ima4}></DataCard>
        </div>
        <div className="col-lg-4 col-sm-6  mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt=""
            titulo=""
            subTitulo=''
            backgroundImage={ima5}></DataCard>
        </div>
        <div className="col-lg-4 col-sm-6  mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt=""
            titulo=""
            subTitulo=''
            backgroundImage={ima6}></DataCard>
        </div>
        <div className="col-lg-6 col-sm-12 mt-4 mb-4 d-flex align-items-center">
          <div className='w-100'>
            <h1 className='text-center'>Articulos para hogar</h1>
            <h3 className='text-center'>Fabricamos articulos de decoración para espacios abiertos y cerrados, relizando envios en todo el <strong>Oriente Antioqueño</strong></h3>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 mb-4">
          <DataCard
            color='#D9D9D9'
            txtColor="#FFF" 
            buttom={true}
            botonTxt="Decora ahora"
            titulo="Recibe asesoría"
            subTitulo='Escribenos y empieza a transformar tu hogar hoy mismo'
            backgroundImage={ima8}
            url='https://wa.me/573183061943?text=Hola%20NASE,%20me%20gustaría%20recibir%20información'></DataCard>
        </div>

      </div>
    </div>
  );
};

export default Home;
