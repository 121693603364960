// Importa React y el archivo de estilos
import React from 'react';
import '../styles/DataCard.scss';

// Define el tipo de prop para el componente
interface DataCardProps {
  url?: string; // Cambiado a string
  titulo?: string;
  botonTxt?: string;
  subTitulo?: string;
  buttom?: boolean;
  color?: string;
  txtColor?: string;
  backgroundImage?: string; // Nuevo prop para la imagen de fondo
}

// Componente DataCard
const DataCard: React.FC<DataCardProps> = (props) => {
  // Establece los valores predeterminados para las props
  const {
    titulo = '',
    subTitulo = '',
    botonTxt = '',
    buttom = true,
    color = 'papayawhip',
    txtColor = '#000',
    backgroundImage = '',
    url = ''
  } = props;

  // Establece el estilo de la imagen de fondo
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`, // Usa la imagen de fondo definida en props
    backgroundColor: color,
    backgroundSize: 'cover', // Ajusta la imagen para cubrir todo el contenedor
    backgroundPosition: 'center', // Centra la imagen en el contenedor
  };

  return (
    <div className="card-container container-fluid d-flex flex-column justify-content-end align-items-center pb-5 pt-5" style={backgroundStyle}>
      <div className="row d-flex justify-content-center align-items-start h-100">
        <div className={`col-12 d-flex flex-column align-items-center ${buttom ? 'justify-content-end' : 'justify-content-start'} h-100`}>
          <h1 className="text-center p-0 m-0 mb-2" style={{ color: txtColor }}>{titulo}</h1>
          <h2 className="text-center p-0 m-0" style={{ color: txtColor }}>{subTitulo}</h2>
          {botonTxt && (
            <a href={url} className="btn btn-primary mt-4" target='_blanc'>{botonTxt}</a>
          )}
        </div>
        <div className={`col-12 d-flex justify-content-center h-50 ${buttom ? 'd-none' : ''}`}></div>
      </div>
    </div>
  );
};

export default DataCard;
